import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import ContactForm from "../components/generic/contact-form"

const Contact = () => {
  const data = useStaticQuery(query)
  const stockImage = data.stockImage.childImageSharp.fluid
  const phoneNumber = data.phoneNumber.siteMetadata.company.contact.phone

  return (
    <Layout>
      <SEO
        title="Contact"
        description="Get in touch with us. Become a member client and make your business more secure and productive."
      />
      <PageTitle title="Contact" />
      <div className="page-contact">
        <div className="page-contact__content-container">
          <div className="page-contact__text-container">
            <div className="page-contact__contact-text-heading">
              <h2 className="heading-secondary heading-secondary--heading-underline">
                Don't Hesitate. Just <em>Contact Us!</em>
              </h2>
            </div>
            <div className="page-contact__contact-text">
              <p className="paragraph">
                <em>
                  We absolutely will not spam you, sell your information,
                  up-sell you, or otherwise make you regret contacting us
                </em>
                . We work hard to implement excellent busienss practices, and{" "}
                <em>
                  we only want to work with motivated, discerning business
                  partners
                </em>
                . If it turns out that our service isn't the right fit for you,
                so be it and best of luck!
              </p>
            </div>
          </div>
          <div className="page-contact__contact-content">
            <div className="page-contact__image-container">
              <div className="page-contact__image">
                <Img fluid={stockImage} />
              </div>
              <div className="page-contact__call-container">
                <a href={"tel:" + phoneNumber}>
                  <div className="page-contact__call">
                    <div className="page-contact__call-icon">
                      <i className="icon-phone"></i>
                    </div>
                    <div className="page-contact__call-text">
                      <div className="page-contact__call-cta">Southern CA - Call Now!</div>
                      <div className="page-contact__call-number">
                        {phoneNumber}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="page-contact__contact-form-container">
              <ContactForm>
                <h2 className="heading-secondary">Get in Touch!</h2>
                <p className="paragraph">
                  We want to hear from you.{" "}
                  <em>
                    Contact us to see if you're a good fit for our membership
                    program!
                  </em>
                  .
                </p>
              </ContactForm>
            </div>
          </div>
          <div className="page-contact__text-container">
            <div className="page-contact__contact-text-heading">
              <h2 className="heading-secondary heading-secondary--heading-underline">
                More Good Reasons to <em>Contact Us</em>
              </h2>
            </div>
            <div className="page-contact__contact-text">
              <p className="paragraph">
                <em>We can give you a free IT infrastructure analysis.</em> A
                huge number of{" "}
                <em>
                  businesses, both large and small, have fragile, insecure IT
                  infrastructures that are waiting to crumble under their own
                  weight
                </em>
                . It takes an IT specialist to foresee most of the common looming
                problems.{" "}
                <em>
                  We can see those problems and prevent them from hurting you.
                </em>{" "}
                Many businesses are also struggling to remain productive due to
                inefficient IT infrastructures which also lead to employee
                down-time. Every click counts in an modern business, and{" "}
                <em>
                  your employees may be losing hundreds to thousands of hours of
                  productive time
                </em>{" "}
                as a result of poor IT infrastructure. When you partner with us,
                we'll be able to start reclaiming lost time for you.
                Additionally,{" "}
                <em>
                  over time we can help you develop more effective workflows
                  creating more time savings and greater productivity.{" "}
                </em>{" "}
                Business is all about learning. The faster you learn, and the
                more productive you are, the more you can succeed. We can take
                you there.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

const query = graphql`
  query {
    stockImage: file(relativePath: { eq: "customer-service-contact.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    name: site {
      siteMetadata {
        company {
          name {
            dba
          }
        }
      }
    }
    phoneNumber: site {
      siteMetadata {
        company {
          contact {
            phone
          }
        }
      }
    }
  }
`
